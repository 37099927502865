body {
  padding: 0 !important;
  margin: 0 !important;
}

.blocklyFlyoutScrollbar[display='none'] {
  display: none;
}

/* Main Styles */
.d-flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-stretch {
  align-items: stretch;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-space-around {
  justify-content: space-around;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.m0 {
  margin: 0rem;
}
.m1 {
  margin: 0.5rem;
}
.m2 {
  margin: 1rem;
}
.m3 {
  margin: 1.5rem;
}
.m4 {
  margin: 2rem;
}
.m5 {
  margin: 2.5rem;
}
.mt0 {
  margin-top: 0rem;
}
.mt1 {
  margin-top: 0.5rem;
}
.mt2 {
  margin-top: 1rem;
}
.mt3 {
  margin-top: 1.5rem;
}
.mt4 {
  margin-top: 2rem;
}
.mt5 {
  margin-top: 2.5rem;
}
.mr0 {
  margin-right: 0rem;
}
.mr1 {
  margin-right: 0.5rem;
}
.mr2 {
  margin-right: 1rem;
}
.mr3 {
  margin-right: 1.5rem;
}
.mr4 {
  margin-right: 2rem;
}
.mr5 {
  margin-right: 2.5rem;
}
.mb0 {
  margin-bottom: 0rem;
}
.mb1 {
  margin-bottom: 0.5rem;
}
.mb2 {
  margin-bottom: 1rem;
}
.mb3 {
  margin-bottom: 1.5rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.mb5 {
  margin-bottom: 2.5rem;
}
.ml0 {
  margin-left: 0rem;
}
.ml1 {
  margin-left: 0.5rem;
}
.ml2 {
  margin-left: 1rem;
}
.ml3 {
  margin-left: 1.5rem;
}
.ml4 {
  margin-left: 2rem;
}
.ml5 {
  margin-left: 2.5rem;
}
.p0 {
  padding: 0rem;
}
.p1 {
  padding: 0.5rem;
}
.p2 {
  padding: 1rem;
}
.p3 {
  padding: 1.5rem;
}
.p4 {
  padding: 2rem;
}
.p5 {
  padding: 2.5rem;
}
.pt0 {
  padding-top: 0rem;
}
.pt1 {
  padding-top: 0.5rem;
}
.pt2 {
  padding-top: 1rem;
}
.pt3 {
  padding-top: 1.5rem;
}
.pt4 {
  padding-top: 2rem;
}
.pt5 {
  padding-top: 2.5rem;
}
.pr0 {
  padding-right: 0rem;
}
.pr1 {
  padding-right: 0.5rem;
}
.pr2 {
  padding-right: 1rem;
}
.pr3 {
  padding-right: 1.5rem;
}
.pr4 {
  padding-right: 2rem;
}
.pr5 {
  padding-right: 2.5rem;
}
.pb0 {
  padding-bottom: 0rem;
}
.pb1 {
  padding-bottom: 0.5rem;
}
.pb2 {
  padding-bottom: 1rem;
}
.pb3 {
  padding-bottom: 1.5rem;
}
.pb4 {
  padding-bottom: 2rem;
}
.pb5 {
  padding-bottom: 2.5rem;
}
.pl0 {
  padding-left: 0rem;
}
.pl1 {
  padding-left: 0.5rem;
}
.pl2 {
  padding-left: 1rem;
}
.pl3 {
  padding-left: 1.5rem;
}
.pl4 {
  padding-left: 2rem;
}
.pl5 {
  padding-left: 2.5rem;
}
.w75 {
  width: 75%;
}
/* End Main Styles */

#loftyWindow {
  padding: 0 !important;
  display: flex;
  width: 100%;
  height: 100vh;
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100vh;
}

#loftyCanvasContainer {
  flex-grow: 1;
  background-color: #dadcdb;
  border-left: 2px solid #712d9e;
  border-right: 2px solid #712d9e;
}

#loftyComponentsToolbar {
  background-color: whitesmoke;
  min-width: 50px !important;
  max-width: 10vw;
}

#loftyPropertiesToolbar {
  background-color: whitesmoke;
  min-width: 400px !important;
  max-width: 20vw;
  padding: 0.5rem;
}

.lofty-button {
  background-color: #1345a6;
  border-radius: 8px;
  border: 1px solid #dadcdb;
  color: white;
  cursor: pointer;
  min-height: 50px;
  min-width: 50px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.lofty-button:hover {
  background-color: #29b2ef;
}

.lofty-button .animatedLine {
  display: block;
  position: absolute;
  background-color: #712d9e;
}

.lofty-button .animatedLine:nth-child(1) {
  left: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  transform: scale(0);
  transform-origin: top;
  transition: transform 0.5s;
}

#page-container {
  border: 1px solid #29b2ef;
  border-style: dotted;
  flex-grow: 1;
}

.bg-white {
  background: white;
}

.bg-blue-light {
  background: #29b2ef;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-grab {
  cursor: grab !important;
}

/* Css para Login page */

.container-centered {
  width: 400px;
  margin: auto;
}

.lofty-main-row {
  width: 100%;
  min-height: 1rem;
}

.default-container {
  padding: 1rem;
  background-color: #dadcdb;
}

.lofty-editor-element {
  border: 1px dotted transparent;
}

.lofty-editor-element-active {
  outline: 1px dashed #1345a6;
  z-index: 1;
}

.page-editor-background {
  background: #2b2a2a;
}

.bg-primary {
  background: #2b2a2a;
}

.text-white {
  color: white;
}

.lofty-logo {
  height: 45px;
  padding: 5px;
  object-fit: contain;
}

.toolbar-h {
  height: calc(100vh - 45px);
}

/* Lofty Login V2 */
.lofty-main-container {
  width: 75%;
  height: fit-content;
  background-color: #fff;
}

.lofty-container-centered-login {
  width: 85%;
  height: 70%;
  margin: auto;
  margin-top: 40px;
}

.lofty-container-right-img {
  position: relative;
}

.lofty-container-right-text {
  font-family: 'Roboto', sans-serif;
  font-size: 56px;
  color: #dadcdb;
  margin: auto;
  position: absolute;
  top: 35%;
  left: 1%;
}

.lofty-container-right-text-low {
  font-family: 'Roboto', sans-serif;
  font-size: 96px;
  font-weight: bold;
  color: #dadcdb;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 20%;
}

.lofty-container-centered-password {
  width: 85%;
  height: 70%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.container-carousel-template-suggestion {
  width: 100% !important;
}

.affiliate-form-input {
  border: 1px solid #c4c4c4;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  min-height: 2.4rem;
}

.affiliate-input-label {
  color: #14569e;
  font-weight: bold;
  width: 100%;
}
.approved-affiliation-text {
  color: #14569e;
  font-weight: bold;
  font-size: 1.5rem;
}

.affiliate-count-number {
  color: #14569e;
  font-weight: bold;
  font-size: 2.7rem;
  border-bottom: 1px solid #c4c4c4;
}

.affiliate-count-label {
  color: #292151;
  font-weight: bold;
  font-size: 1.3rem;
  width: 12rem;
}

.affiliate-date-label {
  color: #292151;
  font-weight: bold;
  font-size: 1rem;
  min-width: 6rem;
  max-height: 2.5rem;
  height: 2.5rem;
}

.affiliate-date-input {
  border: 1px solid #c4c4c4;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  max-height: 2.5rem;
  height: 2.5rem;
}

.referrals-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.referrals-header {
  background-color: #d9edf6;
}

.referrals-table-header {
  text-align: left;
  color: #292151;
  font-size: 0.8rem;
  padding: 0.5rem;
}

.referrals-table-code {
  padding: 0.5rem;
  font-weight: bold;
  color: black;
  font-size: 1rem;
}

.referrals-table-other {
  padding: 0.5rem;
  color: black;
  font-size: 0.9rem;
}

.referrals-row {
  border-bottom: 1px solid #C4C4C4;
}

.referrals-table-body {
  display: block;
  max-height: 18rem; /* Limit height to 10rem */
  overflow-y: auto; /* Enable vertical scrolling */
}

.referrals-header, .referrals-row {
  display: table;
  width: 100%;
  table-layout: fixed; /* Ensure even distribution of column width */
}
